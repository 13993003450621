import 'jquery';

jQuery(document).ready(function () {
    if (jQuery(window).width() < 768) {
        var header = jQuery("#header");
        jQuery(window).scroll(function() {
            var scroll = jQuery(window).scrollTop();
    
            if (scroll >= 50) {
                header.addClass("darkHeader");
            } else {
                header.removeClass("darkHeader");
            }
        });    
        
    } else {
        
        var header = jQuery("#header");
        jQuery(window).scroll(function() {
            var scroll = jQuery(window).scrollTop();
    
            if (scroll >= 50) {
                header.addClass("darkHeader");
            } else {
                header.removeClass("darkHeader");
            }
        });    
    }
});

jQuery('#mobileDropdownButton').on('click', function(){
    jQuery('#mobileDropdown').slideToggle();
    jQuery('#body').toggleClass('position-fixed');
    jQuery('.m-header-hamburger-bars').toggleClass('-active');
    jQuery('.m-header-logo').toggleClass('-hide');
    jQuery('.m-header-logo.-mobile').toggleClass('-active');
});