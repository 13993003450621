// This file contains all parachute imports, e.g:
// import './parachute/foo/foo.js';
// import './parachute/bar/bar.js';

import './age-cookie/age-cookie';
import './animation/animation';
import './archive/archive';
import './basket-update/basket-update';
import './basket-overview/basket-overview';
import './cart-summary/cart-summary';
import './collection/collection';
import './header/header';
import './nav/nav';
import './online-collection/online-collection';
import './search/search';
import './serves/serves';
import './banner/banner';

// Publicly accessible methods for this build, useful for callbacks in script tags etc.
// Try and keep most things inside this webpack bundle though
import { publicExports } from './public-exports';
window._Parachute = publicExports || {};
