import 'jquery';
import 'bootstrap';
import { default as $ } from 'jquery';

$(document).on('DOMContentLoaded', function() {
    $(".m-banner-modal-close").on("click", function(){
        $(this).closest(".modal-content").find("video").get(0).pause();
    });

    function playVideo(modal) {
        $(modal).find("video").get(0).play();
    }

    function pauseVideo(modal) {
        $(modal).find("video").get(0).pause();
    }

    $(".banner-button-modal").on("click", function(){
        var targetModal = $(this).data('bs-target');
        playVideo(targetModal);
    });
    
    const modals = document.querySelectorAll('.modal');

    modals.forEach(modal => {
        modal.addEventListener('hidden.bs.modal', event => {
            pauseVideo(modal);
        });
    });
});